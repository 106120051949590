// Дополнительные действия при логине/выходе
export default (ctx) => {
  const { store, $auth } = ctx

  function onLogin () {
    // $privateChatNotifications.start()
    // store.dispatch("user/FETCH_FAVORITE_SESSIONS")
  }

  function onLogout () {
    // $privateChatNotifications.stop()
    store.commit("user/RESET_STATE")
  }

  if ($auth.loggedIn) onLogin()

  $auth.$storage.watchState("loggedIn", (loggedIn) => {
    (loggedIn ? onLogin : onLogout)()
  })
}
